<template>
  <div class="insight_compare">
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title">综合评估</h2>
      <el-table
        :data="overviewTableData"
        :cell-style="{ color: '#34355B' }"
        :header-cell-style="{ color: '#8C98A5' }"
      >
        <el-table-column
          v-for="item in overviewColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title">平台分布</h2>
      <div style="text-align: right">
        <el-radio-group v-model="platformType" @change="getPlatform">
          <el-radio label="percent">占比</el-radio>
          <el-radio label="amount">数量</el-radio>
        </el-radio-group>
      </div>
      <h3 class="insight_subtitle">费用</h3>
      <el-table
        :data="platformCostTableData"
        :cell-style="{ color: '#34355B' }"
        :header-cell-style="{ backgroundColor: '#F6F8FA', color: '#636A72' }"
      >
        <el-table-column
          v-for="item in platformCostColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
      <h3 class="insight_subtitle">曝光</h3>
      <el-table
        :data="platformExposureTableData"
        :cell-style="{ color: '#34355B' }"
        :header-cell-style="{ backgroundColor: '#F6F8FA', color: '#636A72' }"
      >
        <el-table-column
          v-for="item in platformExposureColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title">Tier分布</h2>
      <div style="display: flex; justify-content: space-between">
        <el-radio-group v-model="tierPlatform" size="mini" @change="getTier">
          <el-radio-button label="wechat">微信</el-radio-button>
          <el-radio-button label="weibo">微博</el-radio-button>
          <el-radio-button label="douyin">抖音</el-radio-button>
          <el-radio-button label="xiaohongshu">小红书</el-radio-button>
          <el-radio-button label="bilibili">哔哩哔哩</el-radio-button>
        </el-radio-group>
        <el-radio-group v-model="tierType" @change="getTier">
          <el-radio label="percent">占比</el-radio>
          <el-radio label="amount">数量</el-radio>
        </el-radio-group>
      </div>
      <h3 class="insight_subtitle">费用</h3>
      <el-table
        :data="tierCostTableData"
        :cell-style="{ color: '#34355B' }"
        :header-cell-style="{ backgroundColor: '#F6F8FA', color: '#636A72' }"
      >
        <el-table-column
          v-for="item in tierCostColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
      <h3 class="insight_subtitle">曝光</h3>
      <el-table
        :data="tierExposureTableData"
        :cell-style="{ color: '#34355B' }"
        :header-cell-style="{ backgroundColor: '#F6F8FA', color: '#636A72' }"
      >
        <el-table-column
          v-for="item in tierExposureColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title">投放节奏</h2>
      <div style="display: flex">
        <div
          style="
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: 20px;
            margin-top: 4px;
          "
        >
          <legend-com
            v-for="legend in echartLegend"
            :key="legend.name"
            :data="legend"
          />
        </div>
        <div>
          <el-radio-group
            v-model="echartsType"
            style="margin-right: 40px"
            @change="getTrend"
          >
            <el-radio label="cost">费用</el-radio>
            <el-radio label="exposure">曝光</el-radio>
            <el-radio label="engagement">互动</el-radio>
          </el-radio-group>
          <el-radio-group v-model="echartsTime" size="mini" @change="getTrend">
            <el-radio-button label="day">天</el-radio-button>
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <line-bar
        style="height: 400px"
        :xAxis="echartxAxis"
        :series="echartSeries"
      />
    </el-card>
    <el-card style="border-radius: 10px">
      <h2 class="insight_title">内容分析</h2>
      <el-table
        :data="analysisTableData"
        :cell-style="{ color: '#34355B' }"
        :header-cell-style="{ color: '#8C98A5' }"
      >
        <el-table-column
          label="活动"
          prop="campaignName"
          align="center"
          width="300"
        ></el-table-column>
        <el-table-column label="重要内容排序" prop="content">
          <template #default="scope">
            <span
              v-for="(value, index) in Object.entries(scope.row.words)
                .sort((a, b) => b[1] - a[1])
                .slice(0, 20)"
              :key="value[0]"
              style="margin-right: 5px"
            >
              {{ index + 1 }}.{{ value[0] }}({{ value[1] }})
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import legendCom from "../Dashboard/legendCom";
import lineBar from "../Dashboard/lineBar";
import {
  getCampaignInsightTrend,
  getComparisonContent,
  getComparisonOverview,
  getComparisonPlatform,
  getComparisonTier,
} from "@/api/campaignInsight";
import { mapGetters } from "vuex";

export default {
  name: "insightCompare",
  data() {
    return {
      overviewTableData: [],
      overviewColData: [
        {
          prop: "campaignName",
          label: "活动",
          align: "center",
          width: null,
        },
        {
          prop: "productName",
          label: "产品",
          align: "center",
          width: 300,
        },
        {
          prop: "startDate",
          label: "发起时间",
          align: "center",
          width: 140,
        },
        {
          prop: "cost",
          label: "费用",
          align: "center",
          width: 160,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue / 100) : "--";
          },
        },
        {
          prop: "exposure",
          label: "曝光",
          align: "center",
          width: 150,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          prop: "engagement",
          label: "互动",
          align: "center",
          width: 150,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue) : "--";
          },
        },
      ],
      platformType: "percent",
      platformCostTableData: [],
      platformCostColData: [
        {
          prop: "campaignName",
          label: "活动",
          align: "center",
          width: 300,
        },
        {
          prop: "total",
          label: "总计",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue / 100) : "--";
          },
        },
        {
          prop: "douyin",
          label: "抖音",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "wechat",
          label: "微信",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "weibo",
          label: "微博",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "xiaohongshu",
          label: "小红书",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "bilibili",
          label: "B站",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
      ],
      platformExposureTableData: [],
      platformExposureColData: [
        {
          prop: "campaignName",
          label: "活动",
          align: "center",
          width: 300,
        },
        {
          prop: "total",
          label: "总计",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          prop: "douyin",
          label: "抖音",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "wechat",
          label: "微信",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "weibo",
          label: "微博",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "xiaohongshu",
          label: "小红书",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "bilibili",
          label: "B站",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.platformType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
      ],
      tierPlatform: "wechat",
      tierType: "percent",
      tierCostTableData: [],
      tierCostColData: [
        {
          prop: "campaignName",
          label: "活动",
          align: "center",
          width: 300,
        },
        {
          prop: "total",
          label: "总计",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue / 100) : "--";
          },
        },
        {
          prop: "tier1",
          label: "Tier1",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "tier2",
          label: "Tier2",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "tier3",
          label: "Tier3",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          prop: "koc",
          label: "KOC",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
      ],
      tierExposureTableData: [],
      tierExposureColData: [
        {
          prop: "campaignName",
          label: "活动",
          align: "center",
          width: 300,
        },
        {
          prop: "total",
          label: "总计",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          prop: "tier1",
          label: "Tier1",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "tier2",
          label: "Tier2",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "tier3",
          label: "Tier3",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          prop: "koc",
          label: "KOC",
          align: "center",
          width: null,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.tierType === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
      ],
      echartsType: "cost",
      echartsTime: "day",
      originData: [],
      echartLegend: [
        { name: "cam1", color: "#7CA526" },
        { name: "cam2", color: "#7CA526" },
        { name: "cam3", color: "#7CA526" },
      ],
      echartxAxis: {
        data: [
          201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
          202001, 202002,
        ],
        type: "category",
      },
      echartSeries: [
        {
          data: [
            73079874, 73584250, 55022549, 62489669, 54538919, 42380599,
            28357890, 42266074, 53824757, 38509705,
          ],
          name: "cam1",
          type: "line",
          smooth: true,
        },
        {
          data: [
            73079, 735842, 55022, 624869, 54539, 423899, 2890, 422074, 534757,
            3705,
          ],
          name: "cam1",
          type: "line",
          smooth: true,
        },
      ],
      echartColor: ["#7CA526", "#5E9AF4", "#5B4A99"],
      defaultColors: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
      analysisTableData: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["month"]),
  },
  mounted() {
    this.getOverview();
    this.getPlatform();
    this.getTier();
    this.getTrend();
    this.getContent();
  },
  methods: {
    async getOverview() {
      const { data } = await getComparisonOverview({ id: this.id });
      this.overviewTableData = data;
    },
    async getPlatform() {
      const params = {
        id: this.id,
        type: this.platformType,
      };
      const {
        data: { cost, exposure },
      } = await getComparisonPlatform(params);
      this.platformCostTableData = cost;
      this.platformExposureTableData = exposure;
    },
    async getTier() {
      const params = {
        id: this.id,
        platform: this.tierPlatform,
        type: this.tierType,
        mon: this.month,
      };
      const {
        data: { cost, exposure },
      } = await getComparisonTier(params);
      this.tierCostTableData = cost;
      this.tierExposureTableData = exposure;
    },
    async getTrend() {
      const params = {
        id: this.id,
        xType: this.echartsTime,
        yType: this.echartsType,
      };
      let { data } = await getCampaignInsightTrend(params);
      data = data.filter((item) => item.trend.length);
      this.originData = [].concat(data);
      this.initTrendData(data);
    },
    initTrendData(data) {
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        this.echartLegend.push({
          name: campaign.name,
          color: this.echartColor.concat(this.defaultColors)[i],
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      if (this.echartsTime === "day") {
        // day
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    formatDayDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime);
    },
    getDayAllDate(start, end) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      const oneDay = 24 * 60 * 60 * 1000;
      for (stampDb; stampDb <= stampDe; stampDb += oneDay) {
        dateArr.push(this.timeStampToDate(stampDb));
      }
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatWeekDate(startTimeArr, endTimeArr) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime);
    },
    getWeekAllDate(start, end) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stampDb; stampDb <= stampDe; stampDb += oneWeek) {
        stampArr.push(stampDb);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatMonthDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime);
    },
    getMonthAllDate(start, end) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      this.echartSeries = this.originData.map((campaign, i) => {
        const seriesItem = {
          data: [],
          name: campaign.name,
          type: "line",
          smooth: true,
          color: this.echartColor[i],
        };
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[0].xAxis
        );
        const endTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[campaign.trend.length - 1].xAxis
        );
        const campaignxAxis = this.echartxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        seriesItem.data = this.echartxAxis.data.map((xAxis) => {
          let data = null;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend
              ? this.echartsType === "cost"
                ? (data = trend.yAxis / 100)
                : (data = trend.yAxis)
              : (data = 0);
          }
          return data;
        });
        return seriesItem;
      });
    },
    async getContent() {
      const { data } = await getComparisonContent({ id: this.id });
      this.analysisTableData = data;
    },
  },
  components: {
    legendCom,
    lineBar,
  },
};
</script>

<style lang="scss">
.insight_compare {
  .el-radio-group {
    .is-checked {
      .el-radio__inner {
        background: #e09a29;
      }
    }

    .el-radio {
      .el-radio__input {
        .el-radio__inner {
          border-color: #e09a29;
        }
      }

      .el-radio__label {
        color: #979797;
      }
    }

    .is-active {
      .el-radio-button__inner {
        background-color: #e09a29 !important;
        border-color: #e09a29;
        color: #fff !important;
      }
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      box-shadow: -1px 0 0 0 #e09a29;
    }

    .el-radio-button {
      .el-radio-button__inner {
        background-color: #f8f9fe;
        color: #979797;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.insight_compare {
  flex: 1;
  overflow: auto;

  .insight_title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .insight_subtitle {
    font-size: 18px;
    margin: 10px 0;
  }
}
</style>
